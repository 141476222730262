.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 100%;
}

.app__profile-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;

  h2 {
    font-size: 1.7rem;
    text-align: center;
  }
  
  p {
    text-align: center;
    font-size: 1.2rem;
  }

  img {
    width: 200px;
    height: 190px;
    border-radius: 15px;
    object-fit: cover;
  }

  // @media screen and (min-width: 2000px) {
  //   width: 370px;
  //   margin: 2rem 4rem;

  //   img {
  //     height: 320px;
  //   }
  // }
}
